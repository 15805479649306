
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/platform';

            import controller0 from '/home/builduser/work/8d7c71a662c95c87/packages/devcon-demoapp/src/components/firstWidget/controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/8d7c71a662c95c87/packages/devcon-demoapp/src/components/firstWidget/controller.ts';
            var controllerExport0 = _controllerExport0;
            

  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  
var initI18n = null;

  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = null;

  var experimentsConfig = null;

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/8d7c71a662c95c87/packages/devcon-demoapp/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "devcon-demoapp",
    biConfig: null,
    appName: "Wix DevCon",
    appDefinitionId: "31f9ebc9-5a5f-4774-add1-b48c7dcb89c4",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "PLATFORM_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: null,
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/8d7c71a662c95c87/packages/devcon-demoapp/src/components/firstWidget/controller.ts",
          appName: "Wix DevCon",
          appDefinitionId: "31f9ebc9-5a5f-4774-add1-b48c7dcb89c4",
          projectName: "devcon-demoapp",
          componentName: "firstWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "firstWidget" }],
    true);

    export const createControllers = _createControllers
